import { createStore } from '@stencil/store';
import { CurrentPolicyVersionsResponse } from './clients/policy-client';
const { state } = createStore({
  personAddressTypes: [],
  personAtCompanyAddressTypes: [],
  companyAddressTypes: [],
  personEmailAddressTypes: [],
  personAtCompanyEmailAddressTypes: [],
  companyEmailAddressTypes: [],
  personPhoneNumberTypes: [],
  personAtCompanyPhoneNumberTypes: [],
  companyPhoneNumberTypes: [],
  countries: [],
  prefixes: [],
  suffixes: [],
  pronouns: [],
  languages: [],
  mediaCategories: [],
  socialMediaTypes: [],
  currentPolicyVersions: null as CurrentPolicyVersionsResponse | null,
  tags: [],
  ingredients: [],
  primaryContactTypes: [],
  rootGeographySearchItems: [],
});

export default state;
